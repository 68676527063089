<template>
<v-app>
    <template>
        <v-container>
            <v-spacer class="mb-10"></v-spacer>
        </v-container>
        <v-container>
            <v-card>
                <v-card-title class="info">
                    <h4>My query history</h4>
                    <v-spacer></v-spacer>
                    <v-text-field 
                    v-model="search" 
                    append-icon="mdi-magnify" 
                    label="search" 
                    color="warning" 
                    single-line 
                    hide-details
                    class="p-table">
                    </v-text-field>
                </v-card-title>
                <v-data-table 
                multi-sort 
                :loading="loadingQueries" 
                loading-text="Loading... Please wait" 
                :search="search" 
                :headers="headersQueries" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc" 
                :items="filteredQueries" 
                :items-per-page="10" 
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50]
                }" 
                class="elevation-1 secondary">
                    <template v-slot:item="{ item }">
                        <tr class="p-table">
                            <td>
                                <router-link :to="{ path: '/queryViewer', query: { qid: item.id }}">
                                    <p>
                                        {{truncate(item.query)}}
                                    </p>
                                </router-link>
                            </td>
                            <td>{{item.timestamp | formatDateTime}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.pmids.length}}</td>
                            <td>{{item.was_too_long | withinLimit}}</td>
                            <td>{{item.completed | makeYesNo}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </template>

</v-app>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";

export default {
    components: {},
    name: 'My History',
    data() {
        return {
            pubmed_query: "",
            years_back: 1,
            sortBy: ["timestamp", "pmid_length"],
            sortDesc: [true, true],
            search: '',
            headersQueries: [{
                    text: "Query",
                    align: 'start',
                    value: "query",
                },
                {
                    text: "Date and time",
                    value: "timestamp",
                },
                {
                    text: "Submitted by",
                    value: "uid"
                },
                {
                    text: "Number of articles screened",
                    value: "pmid_length",
                },
                {
                    text: "Exceeded article limit?",
                    value: "was_too_long",
                    filterable: false,
                },
                {
                    text: "Query completed?",
                    value: "completed",
                    filterable: false,
                },
            ],
        }
    },
    computed: {
        ...mapState(["queries", "loadingQueries", "userProfile"]),
        filteredQueries: function() {
            // From "queries" variable called with mapState, only select the objects where 
            //  email property equals email of logged-in user (where query of queries has query.email === userProfile.email)
            const userObject = this.queries.filter(
                query => {
                    return query.email === this.userProfile.email;
                }
            );
            return userObject;
        },
    },
    methods: {
        ...mapActions(["loadQueries"]),
        truncate(text, limit = 100, suffix = '...') {
            if (text.length > limit) {
                return text.substring(0, limit) + suffix;
            } else {
                return text;
            }
        }
    },
    mounted() {
        this.loadQueries();
    },
}
</script>
